import $ from "jquery";

const $doc = $(document);

function toggleAccordionItem() {
  let el = $(this);
  let open = el.find(".open");
  let close =el.find(".close");

  if (el.parent().hasClass("active")) {
    el.attr("aria-expanded", "false");
  } else {
    el.attr("aria-expanded", "true");
  }
  el.next().slideToggle("fast");
  el.parent().toggleClass("active");
  open.toggleClass("hidden rotated");
  close.toggleClass("hidden rotated");
  el.toggleClass("active");
}

$doc.on(
  "click",
  ".new-accordion-item-js .wp-block-button",
  toggleAccordionItem,
);
